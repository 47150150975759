<!--
 * @Author: 庄志莹
 * @Date: 2021-03-01 14:39:26
 * @LastEditTime: 2021-03-15 19:25:21
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \device_control\src\views\loginModule\wxlogin\wxlogin.vue
-->
<template>
  <div>
    <div class="container-bind-success">
      <div class="title d-f j-c">
        <p>绑定失败</p>
      </div>
      <div class="success d-f f-d a-c">
        <div class="success-box d-f j-c a-c">
          <img src="@/assets/img/bind-failure.png" alt="" />
        </div>
        <p>您微信和手机号绑定失败~</p>
        <span>请使用微信扫码登录或手机号登录</span>
      </div>
      <div class="icp d-f f-d a-c j-c">
        <div>©2021 XNIU 苏公网安备 32011502011289号</div>
        <div>苏ICP备2020065401号</div>
        <div>江苏小牛电子科技有限公司</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.container-bind-success {
  height: 100vh;
  width: 100vw;
  padding: 50px 16px 0px 16px;
  position: relative;

  .title {
    width: 100%;
    font-size: 18px;
    font-family: "PingFang SC";
    font-weight: bold;
    color: #222222;
  }

  .success {
    width: 100%;
    margin-top: 80px;

    .success-box {
      width: 101px;
      height: 102px;

      img {
        height: 101px;
        width: 102px;
      }
    }

    p {
      margin-top: 20px;
      font-size: 16px;
      font-family: "PingFang SC";
      font-weight: 500;
      color: #222222;
    }

    span {
      margin-top: 10px;
      font-size: 12px;
      font-family: "PingFang SC";
      font-weight: 400;
      color: #999999;
    }
  }
  .icp {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    font-size: 12px;
    width: 80%;
  }
}
</style>
